import React, { useCallback } from 'react'
import styled from '@emotion/styled'
import GoogleMap from 'google-map-react'
import { BikeIcon, CarIcon, MotorbikeIcon, VanIcon } from 'shop/assets/vehicles'
import { IoMdHome as HomeIcon, IoMdWalk as WalkIcon } from 'react-icons/io'

import env from 'shop/env'
import { Delivery, FullAddress } from 'shop/types/cart'

type LatLng = {
  lat: number
  lng: number
}

/**
 *  The global GoogleMap referejce
 *  create a MapRef type to hold our global maps object
 */

type MapRef = {
  map: any
  maps: any
}

type TrackMapProps = {
  delivery?: Delivery
  destination?: FullAddress
  origin?: FullAddress
  config: any
  originLogoUrl?: string // logo url for orgin marker
}

// instanstatiate our initial mapRef
let mapRef: MapRef = { map: null, maps: null }

const trackingAllowedStatuses = ['delivering', 'almost_delivering']

const TrackMap = ({
  config,
  destination,
  origin,
  delivery,
  originLogoUrl
}: TrackMapProps) => {
  // Pins drawing logic

  const drawPin = useCallback((key: string, lat: number, lng: number, icon) => {
    const pinStyle = {
      padding: '6px',
      width: '32px',
      height: '32px',
      marginLeft: '-16px',
      marginTop: '-16px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative' as any
    }

    const pinBackgroundStyle = {
      width: 'inherit',
      height: 'inherit',
      background: 'lightblue',
      opacity: 0.6,
      borderRadius: '50%',
      position: 'absolute' as any,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    }

    return (
      <StyledPin key={key} lat={lat} lng={lng}>
        <div style={pinStyle}>
          <div style={pinBackgroundStyle}></div>
          {icon}
        </div>
      </StyledPin>
    )
  }, [])

  const iconStyles = {
    position: 'relative' as any,
    zIndex: 1,
    height: '100%',
    width: '100%',
    fill: '#000'
  }

  const strokeIconStyles = { ...iconStyles, fill: 'none', stroke: '#000' }

  const drawDestinationPin = useCallback(() => {
    if (!destination) {
      return
    }

    const { coordinates, zip } = destination
    const { lat, lng } = coordinates

    if ((!lat && lat !== 0) || (!lng && lng !== 0)) {
      return
    }

    return drawPin(
      `destination-${zip}`,
      lat,
      lng,
      <HomeIcon style={iconStyles} />
    )
  }, [destination, drawPin, iconStyles])

  const drawOriginPin = useCallback(() => {
    if (!origin) {
      return
    }

    const { coordinates, zip } = origin
    const { lat, lng } = coordinates

    if ((!lat && lat !== 0) || (!lng && lng !== 0)) {
      return
    }

    const logo = (
      <img
        src={originLogoUrl}
        style={{
          maxWidth: '100%',
          position: 'relative',
          zIndex: 1
        }}
        alt={origin.lineOne}
      />
    )

    return drawPin(`${zip}-origin`, lat, lng, logo)
  }, [origin, drawPin, config])

  const drawCourierPin = useCallback(() => {
    if (
      !delivery?.driver ||
      !trackingAllowedStatuses.includes(delivery.status || '')
    ) {
      return
    }

    const { contactNumber, transportType, coordinates } = delivery.driver
    const { lat, lng } = coordinates

    if ((!lat && lat !== 0) || (!lng && lng !== 0) || !transportType) {
      return
    }

    const vehicleNames = {
      motorbikexl: 'motorbike',
      motorcycle: 'motorbike',
      motorcycle_cargo: 'motorbike',
      motorbike_cargo: 'motorbike',
      bicycle: 'bike',
      bicycle_cargo: 'bike',
      walking: 'walk'
    }

    const vehicle = vehicleNames[transportType.toLowerCase()]
      ? vehicleNames[transportType.toLowerCase()]
      : transportType.toLowerCase()

    const vehicleIcons = {
      walk: <WalkIcon style={iconStyles} />,
      bike: <BikeIcon style={iconStyles} />,
      car: <CarIcon style={iconStyles} />,
      motorbike: <MotorbikeIcon style={iconStyles} />,
      van: <VanIcon style={strokeIconStyles} />
    }

    return drawPin(contactNumber, lat, lng, vehicleIcons[vehicle])
  }, [drawPin, iconStyles, strokeIconStyles, delivery])

  // Map recenter logic

  const fitBounds = useCallback((point1: LatLng, point2: LatLng) => {
    const {
      maps: {
        geometry: { spherical }
      }
    } = google

    const center = calculateCenter(point1, point2)

    const radius1 = spherical.computeDistanceBetween(
      center as any,
      point1 as any
    )
    const radius2 = spherical.computeDistanceBetween(
      center as any,
      point2 as any
    )

    const radius = radius1 > radius2 ? radius1 : radius2

    const circle = new mapRef.maps.Circle({ center, radius }).getBounds()

    mapRef.map.fitBounds(circle)
  }, [])

  const calculateCenter = (point1: LatLng, point2: LatLng): LatLng => ({
    lat: (point1.lat + point2.lat) / 2,
    lng: (point1.lng + point2.lng) / 2
  })

  const setCenter = useCallback(() => {
    const originCoordinates = origin?.coordinates
    const destinationCoordinates = destination?.coordinates
    if (!originCoordinates || !destinationCoordinates) {
      return
    }

    fitBounds(originCoordinates, destinationCoordinates)
  }, [origin, destination, fitBounds])

  // Google api loaded handler

  const handleGoogleApiLoaded = ({ map, maps }: any) => {
    mapRef = { map, maps }
    setCenter()
  }

  const createMapOptions = (maps: any) => ({
    fullscreenControl: false,
    zoomControlOptions: {
      position: google.maps.ControlPosition.RIGHT_CENTER
    }
  })

  return (
    <GoogleMap
      bootstrapURLKeys={{
        key: env.MAPS_API_KEY
      }}
      defaultCenter={env.DEFAULT_MAP_CENTER}
      defaultZoom={12}
      options={createMapOptions}
      onGoogleApiLoaded={handleGoogleApiLoaded}
      yesIWantToUseGoogleMapApiInternals={true}
    >
      {drawOriginPin()}
      {drawCourierPin()}
      {drawDestinationPin()}
    </GoogleMap>
  )
}

interface PinProps {
  lat: number
  lng: number
  key: string
  children?: React.ReactNode
  style?: React.CSSProperties
}

const Pin = (props: PinProps) => <>{props.children}</>

const StyledPin = styled(Pin)(({ theme }: any) => ({
  height: '100px',
  width: '100px',
  display: 'flex',
  alignItems: 'center',
  img: {
    maxWidth: '80px'
  }
}))

export default TrackMap
