import { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { useRouteMatch } from 'react-router-dom'
import {
  useReactRouter,
  useMediaQueries,
  useMerchantStore,
  useConsumerCart
} from 'shop/hooks'
import { NavBar, Banner, PromoBanner, Metadata } from 'shop/components'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import {
  ShopBody,
  ShopFlashMessages,
  ShopHeader,
  ShopInformation,
  ShopBreadcrumbs
} from 'shop/components/Shop'
import { MetaDescription, PageTitle, Canonical } from 'shop/components/SEO'
import ProductErrorModal from 'shop/components/ProductInfo/ProductErrorModal'
import {
  SetupShopPageStore,
  ShopPageContext
} from 'shop/hooks/usePageContext/useShopPage'
import CartErrorModal from 'shop/components/ErrorModals/CartErrorModal'
import { PAGE_SHOP } from 'shop/components/NavBar/utils'

interface Props {
  slug: string
  merchant: string
}

const Shop = ({ slug, merchant }: Props) => {
  const { history } = useReactRouter()
  const { cartError } = useConsumerCart()
  const { storeSeoDescription } = useMerchantStore()

  const [hasProductModalError, setHasProductModalError] = useState(false)

  const [modalMode, setModalMode] = useState(false)
  const { isTablet } = useMediaQueries()

  const match = useRouteMatch('/store/:store/:product')

  useEffect(() => {
    if (history?.location?.state?.hasError) {
      setHasProductModalError(true)
    }
  }, [history?.location?.state?.hasError])

  useEffect(() => {
    if (match) {
      setModalMode(true)
    } else {
      setModalMode(false)
    }
  }, [match])

  return (
    <ShopPageContext.Provider value={SetupShopPageStore()}>
      {!modalMode && <Metadata storeSlug={slug} />}
      {!!cartError && <CartErrorModal errorCode={cartError || ''} />}
      <Canonical url={window.location.origin + window.location.pathname} />
      <PageTitle />
      <MetaDescription seoDescription={storeSeoDescription} type='store' />
      <Background>
        <ShopFlashMessages />
        <ProductErrorModal
          hasError={hasProductModalError}
          onClose={() => {
            setHasProductModalError(false)
            // we must remove the state to prevent it appearing after refresh
            history.replace(history.location.pathname, { hasError: false })
          }}
        />
        <PromoBanner />
        <NavBar transparent={false} page={PAGE_SHOP} storeSlug={slug} />
        <Banner />
        <ShopContent isVisible={!modalMode || (modalMode && isTablet)}>
          <ShopHeader />
          <ShopBody slug={slug} merchant={merchant} />
          <ShopInformation />
          <ShopBreadcrumbs />
        </ShopContent>
        <Bottom />
      </Background>
    </ShopPageContext.Provider>
  )
}

const Background = styled.div(() => ({
  backgroundColor: '#f5f5f5'
}))

const Bottom = styled.div(() => ({
  height: '80px'
}))

const ShopContent = styled.div<
  StyledHTMLElement & { isVisible: boolean },
  Required<Theme>
>(({ theme, isVisible }) => ({
  maxWidth: '1332px',
  margin: '0 auto',
  visibility: isVisible ? 'visible' : 'hidden',

  [theme.mediaQueries.viewport7]: {
    padding: '0 16px 24px 16px'
  },
  [theme.mediaQueries.viewport14]: {
    maxWidth: '2064px',
    padding: '0 72px 24px 72px'
  }
}))

export default Shop
