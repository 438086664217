import { useCallback, createContext, useContext, useState } from 'react'
import {
  QUERY_GET_CONTENT_BY_SLUG,
  QUERY_GET_STORE_BY_SLUG,
  QUERY_GET_SLERP_MERCHANT
} from 'shop/client'
import {
  AppContentQueryResponse,
  MerchantStoreQueryResponse,
  SlerpMerchantQueryResponse
} from './types'
import { useShop } from './useShop'

type LoaderState = {
  fetchAppContent: () => Promise<AppContentQueryResponse | null>
  fetchMerchantStore: (
    storeSlug: string | null
  ) => Promise<MerchantStoreQueryResponse | null>
  fetchSlerpMerchant: () => Promise<SlerpMerchantQueryResponse | null>
}

export const SetupLoader = (): LoaderState => {
  const { useShopClient, config } = useShop()

  const { domain: merchantSlug } = config
  const client = useShopClient()

  const [merchantStoreCache, setMerchantStoreCache] = useState<{
    [key: string]: MerchantStoreQueryResponse | null
  }>({})

  const fetchAppContent = useCallback(async () => {
    if (!merchantSlug || !client) {
      return null
    }

    try {
      const result = await client.query({
        query: QUERY_GET_CONTENT_BY_SLUG,
        variables: {
          merchantSlug: merchantSlug
        }
      })

      const appContent = result?.data?.getContent

      if (!appContent) {
        return null
      }

      return appContent
    } catch {
      return null
    }

    // [client] causes endless api calls
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantSlug])

  const fetchMerchantStore = useCallback(async (storeSlug) => {
    if (!storeSlug) {
      return null
    }

    if (merchantStoreCache[storeSlug]) {
      return merchantStoreCache[storeSlug]
    }

    try {
      const result = await client.query({
        query: QUERY_GET_STORE_BY_SLUG,
        variables: {
          storeSlug: storeSlug
        }
      })

      const merchantStore = result?.data?.getStore
      setMerchantStoreCache(merchantStore)

      if (!merchantStore) {
        return null
      }

      return merchantStore
    } catch {
      return null
    }

    // [client] causes endless api calls
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchSlerpMerchant = useCallback(async () => {
    if (!merchantSlug) {
      return null
    }

    try {
      const result = await client.query({
        query: QUERY_GET_SLERP_MERCHANT,
        variables: {
          merchantSlug
        }
      })

      const slerpMerchant = result?.data?.getMerchant

      if (!slerpMerchant) {
        return null
      }

      return slerpMerchant
    } catch {
      return null
    }

    // [client] causes endless api calls
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantSlug])

  return {
    fetchAppContent,
    fetchMerchantStore,
    fetchSlerpMerchant
  }
}

const defaultResponse = () => new Promise<null>((resolve) => resolve(null))

const initialState: LoaderState = {
  fetchAppContent: defaultResponse,
  fetchMerchantStore: defaultResponse,
  fetchSlerpMerchant: defaultResponse
}

export const LoaderContext = createContext<LoaderState>(initialState)

export const useLoader = () => {
  return useContext(LoaderContext)
}
