import { CurrentLocationState } from 'shop/components'
import { SelectedModifier, CartItem } from 'shop/components/Landing/types'
import {
  AppliedModifiersParams,
  BaseAddress,
  ConsumerCartPayload,
  OrderItem
} from 'shop/types/cart'
import {
  DELIVERY_FULFILLMENT,
  DeliveryAddress,
  Fulfillment,
  FulfillmentType,
  ORDER_AT_TABLE_FULFILLMENT,
  PICKUP_FULFILLMENT
} from 'shop/types'
import { parseTimezoneISO } from './date'
import { format } from 'date-fns'
import { buildAddressFromComponents } from 'shop/components/Landing/addressUtils'
import { timeString } from 'shop/components/Landing/utils'

/**
 * Transforms Hasura "Modifier" format to Elixir "Modifier" format.
 * @param modifiers
 * @returns AppliedModifiersParams[]
 */
export const transformModifiers = (
  modifiers: SelectedModifier[]
): AppliedModifiersParams[] => {
  return modifiers.map((modifier) => {
    return {
      modifierGroupId: modifier.modifier_group_id,
      modifierId: modifier.modifier_id,
      quantity: modifier.quantity
    }
  })
}

/**
 * Transforms Hasura "Cart Item" format to Elixir "Order Item" format.
 * @param cartItems
 * @returns OrderItem[]
 */
export const transformOrderItems = (cartItems: CartItem[]): OrderItem[] => {
  return cartItems.reduce(
    (
      orderItems: OrderItem[],
      { product_variant_id, quantity, applied_modifiers }
    ) => {
      const appliedModifiers = transformModifiers(applied_modifiers)

      return [
        ...orderItems,
        { productVariantId: product_variant_id, quantity, appliedModifiers }
      ]
    },
    []
  )
}

/** Create cart params for initCart on shop page */
export const createDefaultSlerpCartParams = (
  storeId: string,
  defaultFulfillmentType: FulfillmentType,
  defaultFulfillmentInfo: Fulfillment
): ConsumerCartPayload => {
  const fulfillmentWindow = defaultFulfillmentInfo.fulfillmentWindow
  const fulfillmentTime =
    defaultFulfillmentInfo.orderType == 'ASAP'
      ? null
      : format(parseTimezoneISO(fulfillmentWindow.from), 'HH:mm')

  return {
    fulfillmentDate: format(
      parseTimezoneISO(fulfillmentWindow.from),
      'yyyy-MM-dd'
    ),
    fulfillmentTime: fulfillmentTime,
    fulfillmentType: defaultFulfillmentType.toUpperCase() as FulfillmentType,
    storeId: storeId
  }
}

/** Creates delivery address for consumer api/hasura api create cart mutations */
export const constructDeliveryAddress = (
  currentLocation: CurrentLocationState | undefined,
  fulfillmentType: string,
  options: { consumerApi: boolean } = { consumerApi: false }
): BaseAddress | DeliveryAddress | null => {
  if (!!currentLocation && fulfillmentType === 'delivery') {
    if (options.consumerApi) {
      const { line_1, line_2, city, country, zip } = buildAddressFromComponents(
        currentLocation.addressComponents
      )
      return {
        lineOne: line_1,
        lineTwo: line_2,
        city,
        country,
        zip
      } as BaseAddress
    } else {
      const { flatNumber, line_1, line_2, city, country, zip } =
        buildAddressFromComponents(currentLocation.addressComponents)
      return {
        flatNumber,
        line_1,
        line_2,
        city,
        country,
        zip
      } as DeliveryAddress
    }
  } else return null
}

export const fixFulfillmentTime = (
  fulfillmentTime: string | null,
  options: { consumerApi: boolean } = { consumerApi: false }
) => {
  if (fulfillmentTime === 'immediately' || !fulfillmentTime) {
    return options.consumerApi ? null : 'asap'
  }
  return timeString(fulfillmentTime)
}

export const getFulfillmentFlags = (
  fulfillmentType: FulfillmentType | undefined
): { isOrderAtTable: boolean; isDelivery: boolean; isPickup: boolean } => {
  if (!fulfillmentType)
    return { isOrderAtTable: false, isDelivery: false, isPickup: false }
  return {
    isOrderAtTable: fulfillmentType === ORDER_AT_TABLE_FULFILLMENT,
    isDelivery: fulfillmentType === DELIVERY_FULFILLMENT,
    isPickup: fulfillmentType === PICKUP_FULFILLMENT
  }
}
