import React, { useState } from 'react'
import { Button } from 'shop/components/Controls'
import FormInput from '../../Inputs/FormInput'
import { IoGiftOutline as GiftIcon } from 'react-icons/io5'
import styled from '@emotion/styled'
import { LineBreak } from 'shop/components/Shop/commonStyles'
import { useMediaQueries } from 'shop/hooks'

interface Props {
  giftWrapMessage: string | null
  giftWrapPlaceholder: string
  giftWrapPrice: string
  giftWrapText: string
  giftWrapped: boolean
  setGiftWrapMessage: (message: string | null) => void
  setGiftWrapped: (value: boolean) => void
  updateCartGiftWrapping: (message: string | null) => void
}

const GiftWrapping = ({
  giftWrapMessage,
  giftWrapPlaceholder,
  giftWrapPrice,
  giftWrapText,
  giftWrapped,
  setGiftWrapMessage,
  setGiftWrapped,
  updateCartGiftWrapping
}: Props) => {
  const [isFocussed, setIsFocussed] = useState<boolean>(false)
  const [isTouched, setIsTouched] = useState<boolean>(false)
  const { isMobile } = useMediaQueries()

  // Ref to store the previous value of the gift wrap message sent to cart to prevent duplicate requests
  const inputValueRef = React.useRef<string | null>(
    giftWrapped ? giftWrapMessage : null
  )

  const isOverCharacterLimit =
    !!giftWrapMessage && giftWrapMessage?.length > 400

  const showError =
    (isTouched && !isFocussed && !giftWrapMessage?.length) ||
    isOverCharacterLimit

  const errorMessage = !giftWrapMessage?.length
    ? 'Please enter a message for gift wrapping'
    : 'You have exceeded the character limit for this input. Please limit your message to 400 characters'

  const giftWrapPriceNumeric: number = parseFloat(giftWrapPrice)
  const pricingDisplay: string =
    giftWrapPriceNumeric === 0
      ? 'For free'
      : `For only ${giftWrapPriceNumeric.toLocaleString('en-GB', {
          style: 'currency',
          currency: 'GBP'
        })}`

  const buttonText = giftWrapped ? 'Remove' : 'Add'

  /** Updates gift wrap message in consumer cart on blur if different to previous value sent */
  const handleBlur = () => {
    setIsTouched(true)
    setIsFocussed(false)
    if (giftWrapMessage !== inputValueRef.current) {
      inputValueRef.current = giftWrapMessage
      updateCartGiftWrapping(inputValueRef.current)
    }
  }

  /** Handles logic on user clicking add/remove button */
  const handleButtonClick = (e: React.SyntheticEvent<EventTarget>) => {
    e.preventDefault()
    const nextGiftWrapped = !giftWrapped

    if (!nextGiftWrapped && giftWrapMessage) {
      setGiftWrapMessage(null)
      inputValueRef.current = null
      updateCartGiftWrapping(null)
    }

    setGiftWrapped(nextGiftWrapped)
    setIsTouched(false)
  }

  const onGiftWrapInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsTouched(true)
    setGiftWrapMessage(e.target.value)
  }

  const onGiftWrapInputFocus = () => setIsFocussed(true)

  return (
    <>
      <LineBreak data-testid='gift-wrapping-item-line-break' />
      <GiftWrapCTA data-testid='gift-wrapping-item-cta'>
        <GiftIcon />
        <GiftWrapCTAText>
          {`${
            giftWrapText ? giftWrapText : 'Add a customized note'
          } (${pricingDisplay})`}
        </GiftWrapCTAText>
        <Button variant={'primary'} width='12px' onClick={handleButtonClick}>
          {buttonText}
        </Button>
      </GiftWrapCTA>
      {giftWrapped && (
        <FormInputContent>
          <FormInput
            variant='textarea'
            id={'giftWrapMessage'}
            data-testid='gift-wrap-message-textarea'
            value={giftWrapMessage || ''}
            onBlur={handleBlur}
            onFocus={onGiftWrapInputFocus}
            onChange={onGiftWrapInputChange}
            name='giftWrapMessage'
            labelText='Gift Wrap Message'
            errorMessage={errorMessage}
            showError={showError}
            placeholder={
              giftWrapPlaceholder ||
              'What would you like us to write on the note?'
            }
            scrollSettings={{
              scrollOnFocus: true,
              scrollToElementId: isMobile
                ? 'gift-wrap-section'
                : 'checkout-form-wrapper'
            }}
          />
        </FormInputContent>
      )}
    </>
  )
}

const FormInputContent = styled.div(() => ({
  marginBottom: '24px',
  textarea: {
    minHeight: '80px'
  }
}))

const GiftWrapCTA = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  margin: '8px 0 12px',
  '> svg': {
    width: '24px',
    height: '24px',
    flexShrink: 0
  },
  '> button': {
    minWidth: '92px',
    marginLeft: 'auto',
    height: 'unset',
    fontSize: '14px',
    lineHeight: '22px',
    padding: '8px 0',
    borderColor: '#F5F5F5',
    backgroundColor: '#F5F5F5',
    color: '#000'
  }
}))

const GiftWrapCTAText = styled.p(() => ({
  fontSize: '16px'
}))

export default GiftWrapping
