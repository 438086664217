import { ShopConfig } from 'shop/types'
import { ApolloClient } from '@apollo/client'
import { QUERY_GET_CART, MUTATE_CREATE_SLERP_CART } from 'shop/client'
import { CartSession } from './types'
import { computeCartTotal, getDeviceType } from 'shop/components/Cart/utils'
import { SelectedModifier, Cart } from 'shop/components/Landing/types'
import { isDesktop, isMobile } from 'react-device-detect'
import { InitSlerpCartProps } from 'shop/types'
import { ConsumerCart } from 'shop/types/cart'

class CartManager {
  cartSession: CartSession
  config: ShopConfig
  cart: Cart | null
  cartId: string | null
  client: ApolloClient<object>
  requestId: number
  getConsumerCart: () => Promise<ConsumerCart | null>

  constructor(
    cartSession: CartSession,
    client: ApolloClient<object>,
    getConsumerCart: () => Promise<ConsumerCart | null>
  ) {
    this.client = client
    this.cart = null
    this.cartSession = cartSession
    this.config = cartSession.config
    this.cartId = cartSession.cartId
    this.requestId = 0
    this.getConsumerCart = getConsumerCart
  }

  initCart = async (initSlerpCartParams: InitSlerpCartProps) => {
    if (this.cartSession.onCartLoading) this.cartSession.onCartLoading(this)

    const cartParams = {
      ...initSlerpCartParams,
      platformType: getDeviceType(isMobile, isDesktop),
      platformVendor: 'web'
    }

    return this.client
      .mutate({
        mutation: MUTATE_CREATE_SLERP_CART,
        variables: cartParams
      })
      .then((results) => {
        this.cartId = results.data['createSlerpCart']?.id

        return results.data['createSlerpCart']
      })
  }

  updateCartId = async (newCartId: string) => {
    this.cartId = newCartId
    this.cartSession.cartId = newCartId
  }

  /**
   *  This loads the current cart for the current session
   */
  loadCart = async (
    options: { skipConsumerCartQuery: boolean } = {
      skipConsumerCartQuery: false
    }
  ) => {
    // Send an onCartLoading event
    if (this.cartSession.onCartLoading) this.cartSession.onCartLoading(this)

    if (!options.skipConsumerCartQuery) {
      this.getConsumerCart()
    }

    if (!this.cartId) {
      this.cartSession.setCart(null)
      this.cartSession.setIsCartLoading(false)

      return null
    }

    return this.client
      .query({
        query: QUERY_GET_CART,
        variables: {
          id: this.cartId
        },
        fetchPolicy: 'no-cache'
      })
      .then((results) => {
        // NOTE: no error handling when results.data.cart is null
        this.cart = results.data.cart as Cart
        this.cartSession.setCart(this.cart)
        if (this.cartSession.onCartLoad) this.cartSession.onCartLoad(this)

        // generate a requestId for our callbacks, for use on useEffect() or similar.
        this.requestId = new Date().getTime()

        return this.cart
      })
  }

  /** Returns a cart when provided a cart id */
  getCartById = async (cartId: string) => {
    return this.client
      .query({
        query: QUERY_GET_CART,
        variables: {
          id: cartId
        },
        fetchPolicy: 'no-cache'
      })
      .then((results) => {
        return results.data.cart as Cart
      })
  }

  /**
   *
   */
  // const persistCart = () => {
  // this.cartSession.setCart
  // }

  /**
   *  This returns the total cost of the cart
   *
   * @returns total
   */
  total = () => {
    let total = 0
    if (this.cart && this.cart.cart_items) {
      total = computeCartTotal(this.cart.cart_items)
    }
    return total
  }

  /**
   * Compute vat for the cart item.
   *
   * Take note that vat and variantVat are different.
   *
   * vat is for the computed vat amount
   * variantVat is for the variant's vat
   *
   * @returns vat amount
   */
  computeVat = (cartItem: ProductVariantCartProps) => {
    return cartItem.amount * (cartItem.variantVat / 100)
  }
}

export interface ProductVariantCartProps {
  id?: string

  cartId?: string | null

  /** The product variant to add in  the cart  */
  variantId: string

  /** The quantity of products to add */
  quantity: number

  /** The subamount of the product amount * quantity */
  amount: number

  /** The vat amount */
  vat?: number

  /** The product variantVat */
  variantVat: number

  /** The product variantPrice */
  variantPrice: number

  /** Selected modifiers */
  appliedModifiers?: {
    data: SelectedModifier[]
  }
}

export default CartManager
