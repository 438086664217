import {
  differenceInDays,
  differenceInMinutes,
  format,
  isToday,
  isTomorrow,
  roundToNearestMinutes
} from 'date-fns'
import { parseTimezoneISO } from 'shop/utils'
import { PartnerAddress } from '../types'

/**
 * Takes Partner Address and returns readable one line address
 */
export const formatPartnerAddressToString = (address: PartnerAddress) => {
  const { lineOne, lineTwo, city, zip } = address
  let formattedAddress = ''
  if (lineOne) formattedAddress += `${lineOne}, `
  if (lineTwo) formattedAddress += `${lineTwo}, `
  if (city) formattedAddress += `${city}, `
  if (zip) formattedAddress += `${zip}, `
  formattedAddress = formattedAddress.replace(/ {2,}/g, ' ') // remove any double white spaces
  formattedAddress = formattedAddress.trim() // remove trailing whitespace
  formattedAddress = formattedAddress.replace(/,$/, '') // remove any trailing commas

  return formattedAddress
}

/**
 * Format the earliest slot to be displayed appropriately.
 * Timezone is set to UK ('Europe/London')
 */
export const formatEarliestSlot = (
  earliestSlot: {
    from: string
    to: string
  } | null,
  currentDateTime: Date
) => {
  if (!earliestSlot) return `Currently unavailable`
  const earliestSlotFrom = parseTimezoneISO(earliestSlot.from)
  const currentDateTimeMins = roundToNearestMinutes(currentDateTime)
  const earliestSlotFromMins = roundToNearestMinutes(earliestSlotFrom)

  if (differenceInMinutes(earliestSlotFromMins, currentDateTimeMins) < 60) {
    const earliestSlotTo = parseTimezoneISO(earliestSlot.to)
    const earliestSlotToMins = roundToNearestMinutes(earliestSlotTo)
    const mins = {
      from: differenceInMinutes(earliestSlotFromMins, currentDateTimeMins),
      to: differenceInMinutes(earliestSlotToMins, currentDateTimeMins)
    }
    return `${mins.from} - ${mins.to} mins`
  }
  if (
    differenceInMinutes(earliestSlotFromMins, currentDateTimeMins) >= 60 &&
    isToday(earliestSlotFrom)
  ) {
    return `Today from ${format(earliestSlotFromMins, 'HH:mm')}`
  }

  if (isTomorrow(earliestSlotFrom)) {
    return `Tomorrow from ${format(earliestSlotFrom, 'HH:mm')}`
  }

  if (differenceInDays(earliestSlotFrom, currentDateTime) <= 7) {
    return `${format(earliestSlotFrom, 'EEEE')} from ${format(earliestSlotFrom, 'HH:mm')}`
  }

  return `${format(earliestSlotFrom, 'E, do LLL')} from ${format(earliestSlotFrom, 'HH:mm')}`
}
