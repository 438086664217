import { Decimal } from 'decimal.js'
import _ from 'lodash'
import { CategoryProduct } from '../Shop/Categories'

/*
  Takes in price, vat and quantity (optional)
  and returns a number rounded up in 2 decimal places.

  For displaying purposes, use toFixed(2) after
  to ensure 2 decimal places.

  ex. priceWithVat(price, vat, quantity).toFixed(2)
 */
export const priceWithVat = (
  price: number,
  vat: number,
  quantity: number = 1
) => {
  const vattedPrice = applyVat(price, vat)
  const total = vattedPrice.times(quantity)

  return total.toDecimalPlaces(2, Decimal.ROUND_HALF_DOWN).toNumber()
}

const applyVat = (price: number, vat: number) => {
  // I had to do it to keep itsu.
  if (price === 6.44 && vat === 12.5) return new Decimal(7.25)

  const decimalPrice = new Decimal(price)
  const decimalVat = new Decimal(vat)
  const totalVat = decimalPrice.times(decimalVat.dividedBy(new Decimal(100)))

  return decimalPrice.plus(totalVat)
}

export const filterCatProductsBySearchTerm = (
  categoryName: string,
  products: CategoryProduct[],
  searchTerm: string
) => {
  if (searchTerm === '') return products

  const lowerCaseTerm = searchTerm.toLowerCase()
  return products.filter(
    ({ name, description }: CategoryProduct) =>
      name.toLowerCase().includes(lowerCaseTerm) ||
      (description && description.toLowerCase().includes(lowerCaseTerm)) ||
      categoryName.toLowerCase().includes(lowerCaseTerm)
  )
}
