import styled from '@emotion/styled'
import { Button as GenericButton } from 'shop/components/Controls'

interface Props {
  onClick: () => void
  isCartLoading: boolean
}

const ViewCartButton = ({ onClick, isCartLoading }: Props) => {
  return (
    <Button
      variant='secondary'
      data-testid='navbarViewCartButton'
      type='button'
      onClick={onClick}
      isLoading={isCartLoading}
    >
      <span>View cart</span>
    </Button>
  )
}

const Button = styled(GenericButton)(({ theme }: any) => ({
  fontFamily: theme.fonts.heading.family,
  fontSize: theme.fontSizes[0],
  height: '100%',
  color: theme.colors.textBody,
  padding: '0 8px',
  display: 'none',
  whiteSpace: 'nowrap',
  '> *': {
    margin: '0 4px'
  },
  '> div > div > svg': {
    marginRight: '0'
  },
  [theme.mediaQueries.viewport7]: {
    display: 'flex'
  }
}))

export default ViewCartButton
