import { Switch } from './FormElements'
import { inputCharactersLimitValidator } from 'shop/components/Checkout'
import { FieldError, UseFormMethods } from 'react-hook-form'
import FormInput from '../Inputs/FormInput'
import { FormFields } from './types'
import { HeaderWithSwitch } from './commonStyles'
import { useMediaQueries } from 'shop/hooks'
import { useEffect, useRef } from 'react'
import { scrollToElement } from '../Controls/utils'

type OrderNotesProps = {
  formHandle: UseFormMethods<FormFields>
  placeholder: string
  error?: FieldError
}

const OrderNotes = ({ error, placeholder, formHandle }: OrderNotesProps) => {
  const { register, watch } = formHandle
  const orderNotes = watch('orderNotes')
  const orderNotesEnabled = watch('orderNotesEnabled')
  const { isMobile } = useMediaQueries()
  const isMounted = useRef(false)

  useEffect(() => {
    if (orderNotesEnabled && isMobile && !!isMounted.current) {
      // scroll to section when toggling it on for Mobile
      scrollToElement('order-notes-section', 80)
    }
    isMounted.current = true // stops scroll trigger on page load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderNotesEnabled])

  return (
    <>
      <HeaderWithSwitch enabled={!!orderNotesEnabled}>
        <Switch
          formRef={register}
          name={'orderNotesEnabled'}
          labelText='Add order notes'
        />
      </HeaderWithSwitch>
      {orderNotesEnabled && (
        <FormInput
          id={'orderNotes'}
          variant='textarea'
          value={orderNotes}
          data-testid='order-notes-text-area'
          labelText='Order notes'
          formRef={register({
            required: 'Please enter order notes',
            validate: inputCharactersLimitValidator('Order notes')
          })}
          name='orderNotes'
          placeholder={placeholder}
          showError={!!error}
          scrollSettings={{
            scrollOnFocus: true,
            scrollToElementId: isMobile
              ? 'order-notes-section'
              : 'fulfillment-section'
          }}
        />
      )}
    </>
  )
}

export default OrderNotes
