import { isEqual } from 'lodash'
import { useEffect, useCallback, MutableRefObject } from 'react'
import { InputGroup, InputField } from '../Controls'
import { Fields } from './FormElements'
import { UseFormMethods, FieldError } from 'react-hook-form'
import { FormFields } from './types'
import {
  focusNextInput,
  onlySpaces,
  getAddressErrorMessage,
  hasAddressErrors,
  SHIPPING_INPUTS,
  SHIPPING_PREFIX,
  isAddressParamsValid
} from './utils'
import AlertMessage from '../Cart/AlertMessage'
import styled from '@emotion/styled'
import { inputCharactersLimitValidator } from 'shop/components/Checkout'
import useInputKeyEnterHandler from 'shop/hooks/useInputKeyEnterHandler'
import FormInput from '../Inputs/FormInput'
import { HalfWidthFlexContainer } from './commonStyles'
import { DomesticAddress } from 'shop/types/cart'
import { useCheckout } from 'shop/hooks'

type Props = {
  formHandle: UseFormMethods<FormFields>
  deliveryNotePlaceholder: string
  setIsDeliveryLoading: (value: boolean) => void
  updateCart: (address: DomesticAddress) => void
  onBlur: () => void
  displayedAddress: DomesticAddress
  prevAddress: MutableRefObject<DomesticAddress | undefined>
}

const ShippingDetails = ({
  formHandle,
  deliveryNotePlaceholder,
  setIsDeliveryLoading,
  updateCart,
  onBlur,
  displayedAddress,
  prevAddress
}: Props) => {
  const { register, errors, watch } = formHandle
  const deliveryAddress = watch('deliveryAddress')
  const dropoffNotes = watch('dropoffNotes')

  const { trackAddShippingInfo } = useCheckout()

  const addressErrors: { [key: string]: FieldError } | undefined = errors[
    'deliveryAddress'
  ] as { [key: string]: FieldError }
  const addressApiError: FieldError | undefined = errors[
    'address_api'
  ] as FieldError

  const handleQuoting = useCallback(
    (address: DomesticAddress) => {
      if (
        !displayedAddress ||
        hasAddressErrors(addressErrors) ||
        isEqual(address, prevAddress.current)
      ) {
        return
      }

      setIsDeliveryLoading(true)
      prevAddress.current = { ...address }
      const addressValid = isAddressParamsValid(address)

      if (!addressValid) {
        return setIsDeliveryLoading(false)
      }

      trackAddShippingInfo()

      updateCart(address)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addressErrors, setIsDeliveryLoading, updateCart]
  )

  useEffect(() => {
    handleQuoting(displayedAddress)
  }, [handleQuoting, displayedAddress, errors])

  const handleBlur = () => onBlur()

  const {
    line1InputId,
    line2InputId,
    zipInputId,
    cityInputId,
    dropoffNotesInputId,
    saveAddressInputId
  } = SHIPPING_INPUTS
  const inputList = [
    line1InputId,
    line2InputId,
    zipInputId,
    cityInputId,
    dropoffNotesInputId,
    saveAddressInputId
  ]

  useInputKeyEnterHandler({ handleKeyPress: () => focusNextInput(inputList) })

  const currentError = ((): string | null => {
    if (hasAddressErrors(addressErrors)) {
      return getAddressErrorMessage(addressErrors)
    }

    if (Boolean(addressApiError)) {
      return addressApiError.message || ''
    }

    return null
  })()

  return (
    <Container>
      {currentError && (
        <AlertMessageWrapper>
          <AlertMessage skin='mild' type='error' heading={currentError} />
        </AlertMessageWrapper>
      )}

      {/*
        AutoComplete weirdness. To turn off properly in browser, must give an invalid value. ¯\_(ツ)_/¯
        Ref: https://stackoverflow.com/a/54041517
      */}
      <Fields>
        <InputField
          hidden={true}
          formRef={register()}
          name={SHIPPING_PREFIX}
          inputType='checkbox'
        />
        <InputGroup templateColumns={'auto'} gridGap='24px'>
          <FormInput
            id={line1InputId}
            name={line1InputId}
            labelText={'Street Address'}
            value={deliveryAddress?.lineOne || ''}
            formRef={register({
              validate: {
                isRequired: (value) =>
                  Boolean(value.length) && !onlySpaces(value)
              }
            })}
            showError={!!errors.deliveryAddress?.lineOne}
            autoComplete={'street-address'}
            onBlur={handleBlur}
            scrollSettings={{
              scrollOnFocus: true,
              scrollToElementId: 'fulfillment-section'
            }}
          />
          <FormInput
            id={line2InputId}
            name={line2InputId}
            labelText={'Apartment or building name (optional)'}
            value={deliveryAddress?.lineTwo || ''}
            formRef={register()}
            showError={!!errors.deliveryAddress?.lineTwo}
            autoComplete={'not-this-one'}
            onBlur={handleBlur}
            scrollSettings={{
              scrollOnFocus: true,
              scrollToElementId: 'fulfillment-section'
            }}
          />
          <HalfWidthFlexContainer>
            <FormInput
              id={zipInputId}
              name={zipInputId}
              labelText={'Postcode'}
              value={deliveryAddress?.zip || ''}
              formRef={register({
                validate: {
                  isRequired: (value) =>
                    Boolean(value.length) && !onlySpaces(value),
                  length_5: (value) => value.length > 4
                }
              })}
              showError={!!errors.deliveryAddress?.zip}
              autoComplete={'postal-code'}
              onBlur={handleBlur}
              scrollSettings={{
                scrollOnFocus: true,
                scrollToElementId: 'fulfillment-section'
              }}
            />
            <FormInput
              id={cityInputId}
              name={cityInputId}
              labelText={'City'}
              value={deliveryAddress?.city || ''}
              formRef={register({
                validate: {
                  isRequired: (value) =>
                    Boolean(value.length) && !onlySpaces(value)
                }
              })}
              autoComplete={'address-level2'}
              onBlur={handleBlur}
              showError={!!errors.deliveryAddress?.city}
              scrollSettings={{
                scrollOnFocus: true,
                scrollToElementId: 'fulfillment-section'
              }}
            />
          </HalfWidthFlexContainer>
          <FormInput
            id={dropoffNotesInputId}
            name={dropoffNotesInputId}
            labelText='Delivery instructions'
            variant='textarea'
            placeholder={deliveryNotePlaceholder}
            value={dropoffNotes || ''}
            formRef={register({
              validate: inputCharactersLimitValidator('Delivery instructions')
            })}
            showError={!!errors[dropoffNotesInputId]}
            scrollSettings={{
              scrollOnFocus: true,
              scrollToElementId: 'fulfillment-section'
            }}
          />
        </InputGroup>
      </Fields>
    </Container>
  )
}

const AlertMessageWrapper = styled.div(() => ({
  margin: '24px 0'
}))

const Container = styled.div(() => ({
  margin: '24px 0'
}))

export default ShippingDetails
