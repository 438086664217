import { useCallback, useEffect, useRef, useState } from 'react'
import { useConsumerCart } from '.'

/** Handles validateCart polling */
export const usePollValidateCart = () => {
  const { cartLoading, validateCart } = useConsumerCart()
  const [shouldValidateCart, setShouldValidateCart] = useState(false)
  const pollCartVerificationInterval = useRef<NodeJS.Timeout | null>(null)
  const checkStartPollInterval = useRef<NodeJS.Timeout | null>(null)

  /** Pass cartLoading value to handleValidateCart to ensure correct useState value is sent */
  const handleValidateCart = useCallback(() => {
    validateCart(cartLoading)
  }, [cartLoading, validateCart])

  useEffect(() => {
    if (shouldValidateCart) {
      handleValidateCart()
      setShouldValidateCart(false)
    }
  }, [shouldValidateCart, handleValidateCart])

  const startPolling = useCallback(() => {
    const SECOND = 1000
    const currentSeconds = new Date().getSeconds()
    const timeUntilNextMinute = (60 - currentSeconds + 1) * SECOND

    // Schedule the first validation at the start of the next minute
    pollCartVerificationInterval.current = setTimeout(() => {
      setShouldValidateCart(true)
      // Schedule subsequent polling every 30 seconds
      checkStartPollInterval.current = setInterval(() => {
        setShouldValidateCart(true)
      }, 30 * SECOND)
    }, timeUntilNextMinute)
  }, [])

  const stopPolling = useCallback(() => {
    if (pollCartVerificationInterval.current) {
      clearTimeout(pollCartVerificationInterval.current)
      pollCartVerificationInterval.current = null
    }
    if (checkStartPollInterval.current) {
      clearInterval(checkStartPollInterval.current)
      checkStartPollInterval.current = null
    }
  }, [])

  return { startPolling, stopPolling }
}
