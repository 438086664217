import React from 'react'
import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { Button, CartProductErrorCode } from 'shop/components'
import CenterModal from 'shop/components/Modal/CenterModal'
import CloseButton from 'shop/components/Modal/CloseButton'
import { overlayOpenAnim } from 'shop/components/Shop/commonStyles'
import {
  CartErrorMessage,
  ExtendedOrderItem,
  OrderItemV2
} from 'shop/types/cart'
import { CART_MODAL_ORIGIN, Origin, SHOP_ORIGIN } from 'shop/types'
import { getErrorCopy, renderProductItems } from './utils'
import { itemScollListShadows, thinScrollbar } from 'shop/components/common'
import Spinner from 'shop/components/Loader/Spinner'

type Props = {
  invalidItems: (OrderItemV2 | ExtendedOrderItem)[]
  onContinue: (e: React.MouseEvent) => void
  storeName: string
  productErrorCode: CartProductErrorCode
  isOpen?: boolean
  origin: Origin
  onClose?: () => void
  loading?: boolean
}

/** Handles single product errors & displays list of invalid items & CTA */
const CartProductErrorModal = ({
  onClose,
  invalidItems,
  onContinue,
  storeName,
  productErrorCode,
  origin,
  isOpen = true,
  loading = false
}: Props) => {
  if (!invalidItems.length) return <></>

  const isBackButtonEnabled = origin === SHOP_ORIGIN
  const isShopOrigin = origin === SHOP_ORIGIN
  const isCartModalOrigin = origin === CART_MODAL_ORIGIN
  const isPricesChangedError =
    productErrorCode === CartErrorMessage.PRODUCT_PRICES_CHANGED
  const centreModalProps = isShopOrigin
    ? { inModal: true, isOverlay: true, isOpen }
    : {}
  const hasMultipleItems = invalidItems.length > 1

  const InvalidProducts = () => (
    <Container>
      <HeaderBody
        errorCopy={getErrorCopy(
          productErrorCode,
          origin,
          hasMultipleItems,
          storeName
        )}
      />
      <ItemContainer isScrollable={invalidItems.length > 2}>
        {renderProductItems(invalidItems, isPricesChangedError)}
      </ItemContainer>
      {isCartModalOrigin ? (
        <Cta
          onContinue={onContinue}
          isBackButtonEnabled={false}
          primaryBtnText={'Continue and Update Cart'}
        />
      ) : (
        <Cta
          onContinue={onContinue}
          onClose={onClose}
          isBackButtonEnabled={isBackButtonEnabled}
        />
      )}
    </Container>
  )

  return (
    <CenterModal
      styles={overlayOpenAnim(isOpen)}
      canClose={false}
      {...centreModalProps}
    >
      <>
        {loading ? (
          <LoadingState />
        ) : (
          <>
            {isBackButtonEnabled && (
              <CloseContainer>
                <CloseButton
                  handleCloseModal={onClose}
                  variant='newFulfillmentModal'
                />
              </CloseContainer>
            )}
            <InvalidProducts />
          </>
        )}
      </>
    </CenterModal>
  )
}

const CloseContainer = styled.div<StyledHTMLElement>(() => ({
  '> div': {
    position: 'absolute',
    top: '24px',
    right: '24px'
  }
}))

const SpinnerContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }: any) => ({
    height: '100%',
    padding: '152px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontWeight: theme.fontWeights.bold,
    color: 'lightgrey'
  })
)

export const LoadingState = () => (
  <SpinnerContainer>
    <Spinner size='48px' />
  </SpinnerContainer>
)

export const Cta = React.memo(
  ({
    onContinue,
    onClose,
    isBackButtonEnabled,
    primaryBtnText
  }: {
    onContinue: (e: React.MouseEvent) => void
    onClose?: () => void
    isBackButtonEnabled: boolean
    primaryBtnText?: string
  }) => (
    <>
      <Button
        uppercase={false}
        onMouseDown={(e) => e.preventDefault()}
        onClick={onContinue}
      >
        {primaryBtnText ? primaryBtnText : 'Continue'}
      </Button>
      {isBackButtonEnabled && (
        <BackContainer onClick={onClose}>
          <Back>Go back</Back>
        </BackContainer>
      )}
    </>
  )
)

export const HeaderBody = ({
  errorCopy
}: {
  errorCopy: { header: string; body: string }
}) => (
  <>
    <Header>{errorCopy.header}</Header>
    <Text>{errorCopy.body}</Text>
  </>
)

export const Container = styled.div<StyledHTMLElement>(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '32px',
  gap: '24px',
  justifyContent: 'center',
  '& >button': {
    borderRadius: '12px'
  },
  '> p': { margin: 0 }
}))

export const Back = styled.span<StyledHTMLElement>(() => ({
  fontWeight: 500,
  color: '#595959',
  margin: 0
}))

export const BackContainer = styled.div<StyledHTMLElement>(() => ({
  width: 'auto',
  margin: '0 auto',
  padding: '0px 10px',
  cursor: 'pointer'
}))

export const Text = styled.p<StyledHTMLElement>(() => ({
  fontSize: '16px',
  lineHeight: '24px',
  color: '#595959'
}))

export const Header = styled.h1<StyledHTMLElement>(() => ({
  fontSize: '24px',
  alignSelf: 'left'
}))

export const ItemContainer = styled.ul<
  StyledHTMLElement & { isScrollable: boolean; marginTop?: string },
  Required<Theme>
>(({ theme, isScrollable, marginTop = '16px' }) => ({
  width: '100%',
  display: 'flex',
  marginBottom: '8px',
  marginTop: marginTop,
  flexDirection: 'column',
  maxHeight: '175px', // ~70px per item - 2.5 items
  ...(isScrollable
    ? {
        overflowY: 'auto',
        marginRight: '-10px',
        paddingRight: '10px',
        ...(itemScollListShadows(theme) as any),
        [theme['mediaQueries']['viewport7']]: {
          marginRight: '-15px',
          paddingRight: '5px'
        },
        ...(thinScrollbar(theme) as any)
      }
    : {})
}))

export default CartProductErrorModal
