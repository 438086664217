import { RouteComponentProps } from 'react-router-dom'

import { DineIn } from '../shop/pages'
import {
  AppContentLoader,
  SlerpMerchantLoader,
  LoaderSwitcher
} from './loaders'

interface StoreParams {
  slug: string
}

export const LoadDineIn = ({ match }: RouteComponentProps<StoreParams>) => (
  <AppContentLoader>
    {(isParentLoaded) => (
      <SlerpMerchantLoader isParentLoaded={isParentLoaded}>
        {(isParentLoaded) => (
          <LoaderSwitcher isParentLoaded={isParentLoaded}>
            <DineIn slug={match.params.slug} />
          </LoaderSwitcher>
        )}
      </SlerpMerchantLoader>
    )}
  </AppContentLoader>
)
