import styled from '@emotion/styled'
import { IoIosCart as CartIcon } from 'react-icons/io'
import { StickyCartTotalMobileFooter } from '.'

interface Props {
  itemCount: number | undefined
  totalAmount: string
  handleOpenCartModal: () => void
}

const CartTotal = ({ itemCount, totalAmount, handleOpenCartModal }: Props) => {
  const hasOrderItems = !!itemCount
  return (
    <>
      <DesktopContainer onClick={handleOpenCartModal} data-testid='cart-icon'>
        <Icon>
          <CartIcon />
          {hasOrderItems && <Badge>{itemCount}</Badge>}
        </Icon>
        <span>{totalAmount || '...'}</span>
      </DesktopContainer>

      {hasOrderItems && (
        <StickyCartTotalMobileFooter
          cartTotal={totalAmount}
          onClick={handleOpenCartModal}
        />
      )}
    </>
  )
}

const Container = styled.div(({ theme }: any) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  cursor: 'pointer',
  position: 'absolute',
  right: '16px',
  top: '0',
  bottom: '0',
  '> span': {
    display: 'none'
  },
  [theme.mediaQueries.viewport7]: {
    position: 'static',
    justifyContent: 'flex-start',
    '> span': {
      display: 'block'
    }
  }
}))

const DesktopContainer = styled(Container)(({ theme }: any) => ({
  display: 'none',
  fontSize: '14px',
  [theme.mediaQueries.viewport7]: {
    display: 'flex'
  }
}))

const Icon = styled.div(({ theme }: any) => ({
  position: 'relative',
  paddingTop: '4px',
  '> svg': {
    marginRight: '4px',
    transform: 'scale(2)'
  },
  [theme.mediaQueries.viewport7]: {
    display: 'flex',
    paddingTop: '0',
    '> svg': {
      transform: 'scale(1)'
    }
  }
}))

const Badge = styled.div(({ theme }: any) => ({
  position: 'absolute',
  top: '0',
  right: '0',
  transform: 'translate(50%, -50%)',
  backgroundColor: 'red',
  borderRadius: '50%',
  width: '16px',
  height: '16px',
  fontSize: '10px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.mediaQueries.viewport7]: {
    display: 'none'
  }
}))

export default CartTotal
