import React, { useState } from 'react'
import InputField from './../Account/InputField'
import { Button } from 'shop/components'
import { useModal, useAccount } from 'shop/hooks'
import styled from '@emotion/styled'
import Spinner from 'shop/components/Loader/Spinner'
import { useHistory, useLocation } from 'react-router-dom'
import { ApiError } from 'shop/types'

interface FieldProps {
  inFulfillmentModal?: boolean
}

const LoginFields = ({ inFulfillmentModal = false }: FieldProps) => {
  const { pathname } = useLocation()
  const history = useHistory()
  const { closeModal } = useModal()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showMissingFieldsError, setShowMissingFieldsError] = useState(false)
  const [showWrongUserError, setShowWrongUserError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { handleLogin } = useAccount()

  const emptyFieldsExist = email === '' || password === ''

  const onSubmit = (e: React.SyntheticEvent<EventTarget>) => {
    e.preventDefault()
    setIsLoading(true)

    if (emptyFieldsExist) {
      setShowMissingFieldsError(true)
      setIsLoading(false)
    } else {
      const params = {
        email,
        password
      }

      handleLogin(params)
        .then(({ data }) => {
          if (data?.login.customer) {
            closeModal()
            // If login modal is opened from fulfillment modal, push query param
            if (inFulfillmentModal) {
              history.push({
                pathname: pathname,
                search: `?fulfillment=true`
              })
            } else {
              history.replace({
                pathname: pathname
              })
            }
            window.location.reload()
          }
          if (data?.login.errors.length) {
            if (data?.login.errors[0].message === 'INVALID_EMAIL_OR_PASSWORD') {
              setShowWrongUserError(true)
            }
            setIsLoading(false)
          }
        })
        .catch((error: ApiError) => {
          const { graphQLErrors } = error
          if (graphQLErrors && graphQLErrors[0]?.message) {
            if (graphQLErrors[0].message === 'Wrong email or password') {
              setShowWrongUserError(true)
            }
            setIsLoading(false)
            return
          }
          setIsLoading(false)
        })
    }
  }

  return (
    <form onSubmit={onSubmit} data-testid='loginFields'>
      <InputField
        label='Email address'
        type='email'
        testId='loginEmail'
        onChange={(e) => setEmail(e.target.value)}
        showError={showMissingFieldsError && email === ''}
      />
      <InputField
        label='Password'
        type='password'
        testId='loginPassword'
        onChange={(e) => setPassword(e.target.value)}
        showError={showMissingFieldsError && password === ''}
        autoComplete='current-password'
      />
      <Button type='submit' data-testid='loginButton' disabled={isLoading}>
        {isLoading && <Spinner />}
        Login
      </Button>
      {showMissingFieldsError && emptyFieldsExist && (
        <ErrorMessage data-testid='errorMessage'>
          Please fill in the required fields
        </ErrorMessage>
      )}
      {showWrongUserError && (
        <ErrorMessage data-testid='errorMessage'>
          Wrong email or password
        </ErrorMessage>
      )}
    </form>
  )
}

const ErrorMessage = styled.p(({ theme }: any) => ({
  color: theme.colors.state.error[5],
  fontSize: theme.fontSizes[1],
  fontWeight: theme.fontWeights.normal
}))

export default LoginFields
