import { GET_PAY_NEXT_STEP } from 'shop/client'
import { ApolloClient } from '@apollo/client'
import { CustomerDetailsParams } from 'shop/types/cart'

const getPayNextStep = async (client: ApolloClient<object>, cartId: string) => {
  return client.mutate({
    mutation: GET_PAY_NEXT_STEP,
    variables: { cartId }
  })
}

export type UpdateDateCartRecipientDetails = Omit<
  CustomerDetailsParams,
  'email' | 'contactNumPrefix'
>

export { getPayNextStep }
