import React, { useMemo, useState } from 'react'
import styled from '@emotion/styled'
import { Button } from 'shop/components'
import { useShop, useModal, useConsumerCart } from 'shop/hooks'
import Spinner from 'shop/components/Loader/Spinner'
import { CartPayload } from 'shop/components/Cart/types'
import {
  createModifiersCartPayload,
  flattenModifierItemsV2,
  formatModifiersParams
} from '../utils'
import {
  ProductModifierGroup,
  ProductVariant,
  Product
} from '../../Product/types'
import { MdCheck as CheckIcon } from 'react-icons/md'
import { getCurrentCartId, isCartDateExpired } from 'shop/components/Cart/utils'
import { priceWithVat } from 'shop/components/Product/utils'
import { SelectedModifier } from 'shop/components/Landing/types'
import defaultTheme from 'shop/theme/defaultTheme'

import {
  TrackableEvent,
  trackUserActionsFBPixel,
  trackUserActionsGA4
} from 'tracker'
import {
  merchantGA4EcommTrackAddToCart,
  slerpGA4EcommTrackAddToCart
} from 'tracker/GA/ecommerce'
import { FormattedCartItemsTrackParams } from 'tracker/GA/types'
import { CartMutationResponse } from 'shop/types/cart'

interface Props {
  cartParams: CartPayload | undefined
  setCartParams: (arg0?: CartPayload) => void
  isSelectedVariantInStock: boolean
  loadingProduct: boolean
  isProductInStock: boolean
  modifierGroupsValid: boolean
  optionsValid: boolean
  redirectUser: () => void
  product: Product
  modifiers?: ProductModifierGroup[]
  // DONT USE THIS PROP. FOR TESTING LANG E2
  // MockedProvider doesn't support dynamic values and
  // the cart_item_id is auto generated. So i had to
  // put a default value here for testing :(
  testCartItemId?: string
  handleFailedAddToCart: () => void
  setHasFailedAddToCart: (hasFailed: boolean) => void
  selectedVariant?: ProductVariant
  variantQuantity: number
}

const Actions = ({
  cartParams,
  isSelectedVariantInStock,
  loadingProduct,
  isProductInStock,
  modifierGroupsValid,
  optionsValid,
  testCartItemId,
  redirectUser,
  product,
  modifiers,
  handleFailedAddToCart,
  setHasFailedAddToCart,
  selectedVariant
}: Props) => {
  const [cartLabel, setCartLabel] = useState<React.ReactNode | string>(
    'Add to cart'
  )
  const { cart: consumerCart, createOrderItemConsumerCart } = useConsumerCart()
  const { config, partner } = useShop()
  const { isModalOpen, openModal, fulfillmentModal, closeModal } = useModal()

  const formattedPrice = useMemo(() => {
    const format = (price: number) =>
      price.toLocaleString('en-GB', { style: 'currency', currency: 'GBP' })
    const price = format(cartParams?.amount || 0)
    return price
  }, [cartParams?.amount])

  const addToCartCallBack = () => {
    setHasFailedAddToCart(false)
    setCartLabel(
      <>
        <CheckIcon /> Item added to cart
      </>
    )
    setTimeout(() => {
      redirectUser()
    }, 100)
  }

  const ecommTrackAddToCartCallback = (
    params: FormattedCartItemsTrackParams
  ) => {
    // eccommerce tracking
    const variantName = selectedVariant?.name || product.name
    const trackParams = {
      currency: 'gbp',
      value: params.amount,
      items: [
        {
          item_id: params.variantId,
          item_name: product.name,
          affiliation: params.storeName,
          item_brand: params.merchantName,
          item_variant: variantName || product.name,
          price: priceWithVat(params.variantPrice, params.variantVat),
          quantity: params.quantity || 1
        }
      ]
    }
    slerpGA4EcommTrackAddToCart(trackParams)
    merchantGA4EcommTrackAddToCart(trackParams)
  }

  const trackAddToCartCallback = (
    quantity: number,
    appliedModifiers: SelectedModifier[]
  ) => {
    const variantId = selectedVariant?.id || product.defaultVariantId
    const chosenVariantId = variantId || selectedVariant?.name || product.name
    const modifierItems = flattenModifierItemsV2(modifiers)
    const contents =
      appliedModifiers.length > 0
        ? appliedModifiers.map((modifier) => {
            const { modifier_id } = modifier
            const modifierSku = modifier.sku
            const modifierQuantity = modifier.quantity
            const modifierItem = modifierItems.find(
              ({ id }) => id === modifier_id
            )
            const modifierName = modifierItem ? modifierItem.name : ''
            return {
              id: modifierSku ? modifierSku : modifierName,
              quantity: modifierQuantity
            }
          })
        : []

    const body = {
      category: 'Product',
      action: TrackableEvent.ProductAdded,
      label: product.name,
      value: quantity
    }

    trackUserActionsGA4(body, 'slerpGA4Tracking')

    // legacy tracking
    trackUserActionsFBPixel('AddToCart', {
      content_name: product.name,
      content_type: 'product',
      contents: [...contents, { id: chosenVariantId, quantity }]
    })
    trackUserActionsGA4(body, 'merchantGA4Tracking')
  }

  const addItemToCart = async () => {
    if (!cartParams) return
    const { appliedModifiers } = cartParams

    const formattedAppliedModifiers =
      createModifiersCartPayload(appliedModifiers)
    setCartLabel(labelWithSpinner('Adding to cart'))

    const { quantity, variantId } = cartParams

    await createOrderItemConsumerCart({
      quantity,
      variantId,
      modifiers: formattedAppliedModifiers
    })
      .then((res: CartMutationResponse | null) => {
        if (!res) return

        const { cart } = res
        const justAddedOrderItem = cart?.orderItems?.find(
          (item) => item.variantId === variantId
        )

        if (justAddedOrderItem?.id) {
          ecommTrackAddToCartCallback({
            variantId: justAddedOrderItem?.variantId,
            amount: Number(justAddedOrderItem.total.base),
            variantVat: 0,
            variantPrice: Number(justAddedOrderItem.variantPrice.base),
            quantity: justAddedOrderItem.quantity,
            storeName: consumerCart?.store.name || '',
            merchantName: partner?.name || ''
          })
          try {
            trackAddToCartCallback(
              justAddedOrderItem.quantity,
              formatModifiersParams(appliedModifiers)
            )
          } catch (error) {
            console.error(error)
          }
        }
      })
      .finally(() => {
        addToCartCallBack()
        closeModal('fulfillment')
      })
  }

  const addToCart = async () => {
    if (!cartParams) return

    if (!optionsValid || !modifierGroupsValid) {
      handleFailedAddToCart()
      return
    }
    const cartId = getCurrentCartId(config.domain)
    if (!cartId) return openModal('fulfillment')
    if (isCartDateExpired(consumerCart?.fulfillment)) {
      return openModal('fulfillment')
    }
    addItemToCart()
  }

  const cartActionLabel = () => {
    return (
      <>
        {cartLabel === 'Add to cart'
          ? `${cartLabel}・${formattedPrice}`
          : cartLabel}
      </>
    )
  }

  type ButtonState = {
    content: JSX.Element | string
    disabled: boolean
    state: 'loading' | 'available' | 'outofstock'
  }

  // Logic for Add to Cart button's text content and button state
  const getButtonState = (): ButtonState => {
    // return spinner if product is loading
    if (loadingProduct) {
      return {
        content: labelWithSpinner('Loading product'),
        disabled: true,
        state: 'loading'
      }
    } else {
      const outOfStockMessage = 'Out of stock'
      // return 'Out of stock' if product is out of stock
      if (!isProductInStock) {
        return {
          content: outOfStockMessage,
          disabled: true,
          state: 'outofstock'
        }
      }
      // return 'Out of stock' if all options have been selected and the variant is out of stock
      if (optionsValid && !isSelectedVariantInStock) {
        return {
          content: outOfStockMessage,
          disabled: true,
          state: 'outofstock'
        }
      }
      // otherwise return cart action with cost
      return {
        content: cartActionLabel(),
        disabled: false,
        state: 'available'
      }
    }
  }

  return (
    <Action data-testid='productActions'>
      <Button
        testId='add-to-cart-button'
        onClick={addToCart}
        disabled={getButtonState().disabled}
        size='auto'
      >
        {getButtonState().content}
      </Button>
      {isModalOpen('fulfillment') &&
        fulfillmentModal({ addToCart: addItemToCart, inModal: true })}
    </Action>
  )
}

const labelWithSpinner = (text: string) => {
  return (
    <>
      <Spinner />
      {text}
    </>
  )
}

const Action = styled.div(({ theme }: any) => {
  const themeSpace = theme.space ?? defaultTheme.space!
  return {
    alignSelf: 'center',
    fontFamily: theme.fonts.heading.family,
    whiteSpace: 'nowrap',
    marginLeft: 'auto',
    [theme.mediaQueries.viewport7]: {
      position: 'unset',
      boxShadow: 'unset',
      padding: 'unset'
    },
    '& button': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: theme.fontSizes[1],
      lineHeight: '22px',
      padding: `${themeSpace[2]}px ${themeSpace[3]}px`,
      minWidth: '170px',
      width: 'unset'
    }
  }
})

export default Actions
