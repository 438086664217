import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { NavBar } from 'shop/components'
import OrderPageLoader from 'shop/components/Loader/OrderPageLoader'
import { PAGE_ORDER } from 'shop/components/NavBar/utils'
import { useAppContent, useConsumerCart, useShop } from 'shop/hooks'
import { sendPurchaseTracking } from 'tracker/purchaseUtils'

type PurchasePageProps = {
  transactionId: string
}

const PurchasePage = ({ transactionId }: PurchasePageProps) => {
  const { customerId } = useShop()
  const { getConsumerCart, cart } = useConsumerCart()
  const { merchantName } = useAppContent()
  const history = useHistory()

  /** Get consumer cart on page load
   * TODO: Replace with consumer cart loader wrapper context
   */
  const fetchCart = async () => {
    return getConsumerCart().catch((error) => {
      console.error(error)
    })
  }

  useEffect(() => {
    if (!transactionId) return
    fetchCart().then((cart) => {
      if (!cart) return
      const trackAndNavigate = async () => {
        await sendPurchaseTracking({
          consumerCart: cart,
          transactionId,
          merchantName,
          customerId
        })
        // replace the history so users don't navigate back to it and track again.
        history.replace(`/track/${transactionId}`)
      }
      trackAndNavigate()
    })
  }, [transactionId])

  return (
    <>
      <NavBar page={PAGE_ORDER} storeSlug={cart?.store?.slug} />
      <OrderPageLoader />
    </>
  )
}

export default PurchasePage
