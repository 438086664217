import gql from 'graphql-tag'

const GET_PAY_NEXT_STEP = gql`
  mutation payForCart($cartId: ID!) {
    payForCart(cartId: $cartId) {
      nextStep
      transactionId
    }
  }
`

const INTEND_PAYMENT_FOR_CART = gql`
  mutation payForCart(
    $cartId: ID!
    $savePaymentMethod: Boolean
    $paymentMethodId: ID
  ) {
    payForCart(
      cartId: $cartId
      savePaymentMethod: $savePaymentMethod
      paymentMethodId: $paymentMethodId
    ) {
      clientSecret
      nextStep
      connectAccountId
      transactionId
    }
  }
`

const LOCK_CART = gql`
  mutation payForCart($cartId: ID!, $lock: Boolean) {
    payForCart(cartId: $cartId, lock: $lock) {
      nextStep
    }
  }
`

export { GET_PAY_NEXT_STEP, INTEND_PAYMENT_FOR_CART, LOCK_CART }
