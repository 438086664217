import { format, isToday } from 'date-fns'
import { createAddressString } from 'shop/utils/address'
import { ConsumerCart } from 'shop/types/cart'
import { DELIVERY_FULFILLMENT } from 'shop/types'
import { parseTimezoneISO } from 'shop/utils'

// NavBar utils
export const getStoreName = (cart: ConsumerCart | null): string => {
  return cart?.store?.name ?? '...'
}

/** Returns store address if delivery fulfillment, otherwise uses store name */
export const getStoreAddressOrName = (cart: ConsumerCart | null): string => {
  if (cart) {
    if (cart.fulfillment.type === DELIVERY_FULFILLMENT)
      return createAddressString(cart.deliveryAddress)
    return getStoreName(cart)
  }

  return '...'
}

export const buildCartDate = (
  fulfillmentFromString: string | undefined
): string => {
  if (!fulfillmentFromString) return '...'

  const fulfillmentFromDate = parseTimezoneISO(fulfillmentFromString)
  return isToday(fulfillmentFromDate)
    ? `Today (${format(new Date(), 'dd/MM/yyyy')})`
    : format(fulfillmentFromDate, 'dd/MM/yyyy')
}

export const PAGE_CHECKOUT = 'checkout'
export const PAGE_SHOP = 'shop'
export const PAGE_ORDER = 'order'

export type PageType =
  | typeof PAGE_CHECKOUT
  | typeof PAGE_SHOP
  | typeof PAGE_ORDER
