import { useState, useEffect } from 'react'
import { useShop } from 'shop/hooks'
import { UseFormMethods } from 'react-hook-form'
import { DomesticAddress } from 'shop/types/cart'
import { QUERY_CUSTOMER } from 'shop/client/queries/CustomerQueries'
import { customerClient } from 'shop/components/Account/utils'
import { isEqual } from 'lodash'
import { FormFields } from './types'
import ContentLoader from 'react-content-loader'
import styled from '@emotion/styled'

type Props = {
  address: DomesticAddress
  formHandle: UseFormMethods<FormFields>
  inputId: string
}

const SaveAddressCheckbox = ({ formHandle, inputId, address }: Props) => {
  const { register } = formHandle
  const { customerApiKey, customerId, merchant } = useShop()
  const client = customerClient(`${customerApiKey}`, merchant?.id || '')
  const [isLoading, setIsLoading] = useState(false)
  const [hideCheckbox, setHideCheckbox] = useState(true)
  const [savedAddresses, setSavedAddresses] = useState([])

  useEffect(() => {
    if (customerId && customerApiKey) {
      setIsLoading(true)
      client
        .query({
          query: QUERY_CUSTOMER,
          variables: { customerId }
        })
        .then((results) => {
          const { addresses } = results.data.customers_by_pk
          const formattedAddresses = addresses.map(
            ({ city, line_1, line_2, zip }) => ({
              city: city || '',
              lineOne: line_1 || '',
              lineTwo: line_2 || '',
              zip: zip || ''
            })
          )

          setSavedAddresses(formattedAddresses)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [customerId, customerApiKey]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const hasSavedAddress = savedAddresses.some((formattedAddress) =>
      isEqual(address, formattedAddress)
    )
    setHideCheckbox(hasSavedAddress)
  }, [address, savedAddresses])

  return (
    <>
      {isLoading ? (
        <ContentLoader
          data-testid='save-address-loader'
          speed={2}
          style={{
            width: '55px',
            height: '18px',
            borderRadius: '5px',
            alignSelf: 'center'
          }}
        />
      ) : (
        <Container hidden={hideCheckbox}>
          <input type='checkbox' id={inputId} name={inputId} ref={register()} />
          <Label htmlFor={inputId}>Save this address for future use.</Label>
        </Container>
      )}
    </>
  )
}

const Container = styled.div<
  React.HTMLAttributes<HTMLDivElement> & {
    hidden: boolean
  }
>(({ hidden }) => ({
  visibility: hidden ? 'hidden' : 'visible',
  height: hidden ? '0' : 'auto',
  display: hidden ? 'none' : 'block',
  marginBottom: '16px'
}))

const Label = styled.label(({ theme }: any) => ({
  fontWeight: theme.fontWeights.bold,
  fontSize: theme.fontSizes[0],
  marginLeft: '4px'
}))

export default SaveAddressCheckbox
