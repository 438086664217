import { Fragment, useState } from 'react'
import SpinnerModal from 'shop/components/Loader/SpinnerModal'
import styled from '@emotion/styled'
import { IoIosArrowRoundForward as ArrowIcon } from 'react-icons/io'
import { useModal, useConsumerCart, useCart } from 'shop/hooks'
import useRouter from 'use-react-router'
import { Button as GenericButton } from 'shop/components/Controls'
import { checkMinimumOrderValue, getCurrentProductErrors } from '../Cart/utils'
import { OrderItemV2 } from 'shop/types/cart'

const CheckoutButton = () => {
  const { history } = useRouter()
  const { openModal, closeModal } = useModal()
  const [loadingMessage, setLoadingMessage] = useState('')
  const { cart: consumerCart, errors, cartLoading } = useConsumerCart()
  const { cartSession } = useCart()
  const { isCartLoading } = cartSession

  const handleButtonClick = async () => {
    if (isCartLoading || cartLoading) return

    setLoadingMessage('Validating cart')

    if (!consumerCart) {
      setLoadingMessage('')
      openModal('cart')
      return
    }

    const isCartAboveMinOrderValue = checkMinimumOrderValue(
      parseFloat(consumerCart.summary.subtotal.base),
      consumerCart.minimumOrderValue
    )
    const currentProductErrors = getCurrentProductErrors(errors)
    const { orderItems } = consumerCart

    if (currentProductErrors.length || !isCartAboveMinOrderValue) {
      setLoadingMessage('')
      openModal('cart')
    } else {
      checkout(orderItems)
    }
  }

  const checkout = (orderItems: OrderItemV2[]) => {
    if (!consumerCart) return

    closeModal('cart')
    return history.push('/checkout')
  }

  return (
    <Fragment>
      {loadingMessage !== '' && <SpinnerModal message={loadingMessage} />}
      <Button
        data-testid='navbar-checkout-button'
        variant='secondary'
        type='button'
        onClick={handleButtonClick}
        isLoading={isCartLoading || cartLoading}
      >
        <DesktopContent>
          <span>Checkout</span>
          <ArrowIcon />
        </DesktopContent>
        <MobileContent>Pay Now</MobileContent>
      </Button>
    </Fragment>
  )
}

const MobileContent = styled.div(({ theme }: any) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  whiteSpace: 'nowrap',
  [theme.mediaQueries.viewport7]: {
    display: 'none'
  }
}))

const DesktopContent = styled.div(({ theme }: any) => ({
  display: 'none',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.mediaQueries.viewport7]: {
    display: 'flex'
  }
}))

const Button = styled(GenericButton)(({ theme }: any) => ({
  fontFamily: theme.fonts.heading.family,
  fontSize: theme.fontSizes[0],
  height: '100%',
  color: theme.colors.textBody,
  padding: '0 8px',
  '> div > div > svg': {
    marginRight: '0'
  }
}))

export default CheckoutButton
