import { useState, useEffect } from 'react'
import { ItemList } from 'schema-dts'
import { helmetJsonLdProp } from 'react-schemaorg'
import { useRouteMatch } from 'react-router-dom'
import { useAppContent, useMerchantStore, useSlerpMerchant } from 'shop/hooks'
import { ProductWithStock as ProductProps } from 'shop/components/Product/types'
import { Helmet } from 'react-helmet'
import { createOffers, trimUrl } from './utils'

interface Props {
  products: ProductProps[]
}

export const ProductListMeta = ({ products }: Props) => {
  const { isSeoEnabled } = useSlerpMerchant()
  const { merchantName } = useAppContent()
  const { storeName } = useMerchantStore()

  const [modalMode, setModalMode] = useState(false)

  const baseUrl = trimUrl(window.location.origin + window.location.pathname)
  const match = useRouteMatch('/store/:store/:product')

  useEffect(() => {
    if (match) {
      setModalMode(true)
    } else {
      setModalMode(false)
    }
  }, [match])

  if (!isSeoEnabled || modalMode) {
    return <></>
  }

  const itemList = products.map((product, index) => {
    const additionalImages = (product.additionalImages || []).map(
      (image) => image.standard
    )

    return {
      '@type': 'ListItem',
      position: index,
      item: {
        '@context': 'https://schema.org',
        '@type': 'Product',
        name: product.name,
        url: baseUrl + `/${product.slug}`,
        image: [product.image.standard, ...additionalImages],
        description: product.description,
        sku: product.id,
        brand: {
          '@type': 'Brand',
          name: merchantName
        },
        offers: createOffers(
          product,
          `${merchantName} ${storeName}`,
          baseUrl + `/${product.slug}`
        )
      }
    }
  })

  return (
    <Helmet
      script={[
        helmetJsonLdProp<ItemList>({
          '@context': 'https://schema.org',
          '@type': 'ItemList',
          itemListElement: itemList
        })
      ]}
    />
  )
}
