import { useContext, useRef, useState } from 'react'
import { FiArchive as ArchiveIcon } from 'react-icons/fi'
import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { LandingContext } from '../LandingContext'
import { ClosedStoresMessage } from '../LandingForm'
import { PartnerStore } from '../types'
import LandingStoreListItem from './LandingStoreListItem'
import { getEnabledFulfillmentTypes } from '../utils'
import StoreSearchInput from './StoreSearchInput '
import { parseTimezoneISO } from 'shop/utils'

type Props = {
  stores?: PartnerStore[]
}

const LandingStoreList = ({ stores }: Props) => {
  const { merchant } = useContext(LandingContext)
  const [filteredStores, setFilteredStores] = useState<PartnerStore[]>(
    stores || []
  )
  const [searchValue, setSearchValue] = useState('')
  // Immutable Date that is saved on component load
  // This is to calculate minutes before next earliest slot,
  // due to Search mounting and unmounting children component and other re-renders.
  // Ensure time is set to UK timezone. 'Europe/London'
  const onLoadDateTime = useRef<Date>(
    parseTimezoneISO(new Date().toISOString())
  )
  if (!stores?.length) {
    return (
      <ClosedStoresMessage>
        {merchant && merchant.closed_store_message}
      </ClosedStoresMessage>
    )
  }

  return (
    <ListContainer>
      <StoreSearchInput
        allStores={stores}
        setFilteredStores={setFilteredStores}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />
      {!filteredStores.length && <EmptyState searchValue={searchValue} />}
      {filteredStores.map((store) => {
        const { isDeliveryEnabled, isPickupEnabled } =
          getEnabledFulfillmentTypes(store.orderFulfillmentTypes)
        // filter out stores that have no Fulfillment Options
        if (!isDeliveryEnabled && !isPickupEnabled) return null
        return (
          <LandingStoreListItem
            key={store.slug}
            store={store}
            isDeliveryEnabled={isDeliveryEnabled}
            isPickupEnabled={isPickupEnabled}
            onLoadDateTime={onLoadDateTime.current}
          />
        )
      })}
    </ListContainer>
  )
}

const EmptyState = ({ searchValue }: { searchValue: string }) => (
  <EmptyContainer>
    <ArchiveIcon />
    <h2>Sorry, we are unable to find a store with "{searchValue}"</h2>
  </EmptyContainer>
)

const ListContainer = styled.ul<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    marginTop: 0,
    minHeight: '100vh', // maintain scroll position when store list shortens
    [theme.mediaQueries.viewport7]: {
      padding: `0 ${theme.space[3]}px`,
      gap: '21px',
      minHeight: 'unset'
    }
  })
)

const EmptyContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    padding: '48px 16px 16px',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    color: theme.colors['textMute'],
    fontFamily: theme.fonts['heading'].family,
    fontWeight: theme.fonts['heading'].weight,
    '> svg': {
      fontSize: '64px',
      strokeWidth: '1'
    },
    fontSize: '14px',

    [theme.mediaQueries.viewport7]: {
      fontSize: '16px'
    }
  })
)

export default LandingStoreList
