import { useContext } from 'react'
import styled from '@emotion/styled'
import FulfillmentOptions from './FulfillmentOptions'
import { useShop, useModal } from 'shop/hooks'
import { LandingContext } from './LandingContext'
import {
  getStorePreOrderSettings,
  getUpcomingSpecialCutoffs
} from './preOrderUtils'
import { getEnabledPreorderDays } from './datePickerUtils'
import Spinner from '../Loader/Spinner'
import { SpinnerContainer } from './commonStyles'
import LandingHeaderBar from './LandingHeaderBar'
import PartnerDescription from './PartnerDescription'
import LandingStoreList from './LandingStoreList/LandingStoreList'
import LandingViewToggle from './LandingStoreList/LandingViewToggle'

const LoadingSpinner = () => (
  <SpinnerContainer>
    <Spinner size='48px' />
  </SpinnerContainer>
)

const LandingForm = ({ onClose }: { onClose: () => void }) => {
  const { merchant } = useContext(LandingContext)
  const { partner } = useShop()
  const { isModalOpen, loginModal } = useModal()

  const isLoggedIn = localStorage.getItem('customerId') !== null

  return (
    <>
      <LandingHeaderBar onClose={onClose} />
      <LandingContainer>
        {!isLoggedIn &&
          isModalOpen('login') &&
          loginModal({ merchantId: merchant.id })}

        <PartnerDescription
          welcomeMessage={partner?.content.welcome.message}
          welcomeMessageHeader={partner?.content.welcome.header}
        />
        <FormContent />
      </LandingContainer>
    </>
  )
}

const FormContent = () => {
  const { formView } = useContext(LandingContext)
  const { partner } = useShop()
  const { stores } = partner || {}

  return !formView ? (
    <LoadingSpinner />
  ) : (
    <>
      <LandingViewToggle currentView={formView} />
      {formView === 'browse' && <LandingStoreList stores={stores} />}
      {formView === 'fulfillment' && <FulfillmentForm />}
    </>
  )
}

const FulfillmentForm = () => {
  const { merchant, stores, loadingStores, fulfillmentType } =
    useContext(LandingContext)

  const openStores = stores && stores.filter((store) => store.is_open)

  const hasValidPreorderStore = !!stores.find((store) => {
    const preorderSettings = getStorePreOrderSettings(store)
    const acceptsPreorder =
      preorderSettings?.pickup_enabled || preorderSettings?.delivery_enabled
    const enabledCutoffs =
      preorderSettings && preorderSettings.cutoffs
        ? getEnabledPreorderDays(preorderSettings.cutoffs)
        : []
    const upcomingEnabledSpecialCutoffs = preorderSettings?.special_cutoffs_map
      ? getUpcomingSpecialCutoffs(preorderSettings?.special_cutoffs_map, true)
      : []
    const hasValidCutoffs =
      enabledCutoffs.length > 0 || upcomingEnabledSpecialCutoffs.length > 0

    return acceptsPreorder && hasValidCutoffs
  })

  const showForm = openStores.length > 0 || hasValidPreorderStore

  return (
    <>
      {merchant && stores && showForm ? (
        <FulfillmentOptions
          merchant={merchant}
          stores={stores}
          hasPadding={false}
          label={
            <h5>
              1.<span>Choose order type</span>
            </h5>
          }
        />
      ) : loadingStores[fulfillmentType] ? (
        <LoadingSpinner />
      ) : (
        <ClosedStoresMessage>
          {merchant && merchant.closed_store_message}
        </ClosedStoresMessage>
      )}
    </>
  )
}

export const LandingContainer = styled.section(({ theme }: any) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  padding: theme.space[3],
  paddingBottom: 0,
  position: 'relative',
  gap: '32px',
  'div > h5': {
    display: 'flex',
    alignItems: 'center',
    margin: '32px 0 18px',
    fontWeight: 'normal',
    fontSize: '20px',
    textTransform: 'uppercase',

    '&:first-of-type': {
      marginTop: '0'
    },

    '> span': {
      flexGrow: 1,
      marginLeft: '1ch',
      fontSize: '14px'
    },

    [theme.mediaQueries.viewport7]: {
      fontWeight: 'bold'
    }
  }
}))

export const ClosedStoresMessage = styled.h2(({ theme }: any) => ({
  color: theme.colors.textBold,
  fontWeight: theme.fonts.heading.weight,
  fontFamily: theme.fonts.heading.family,
  [theme.mediaQueries.viewport7]: {
    fontSize: theme.fontSizes[5],
    padding: `0 ${theme.space[3]}px`
  }
}))

export default LandingForm
