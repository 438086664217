import { RouteComponentProps } from 'react-router-dom'
import { AppContentLoader, LoaderSwitcher } from 'router/loaders'
import { Checkout } from '../../shop/pages'
import { ConsumerCartLoader } from 'router/loaders/ConsumerCartLoader'

export const CheckoutWrapper = (props: RouteComponentProps) => {
  return (
    <AppContentLoader>
      {(isParentLoaded) => (
        <ConsumerCartLoader isParentLoaded={isParentLoaded}>
          {(isParentLoaded) => (
            <LoaderSwitcher isParentLoaded={isParentLoaded}>
              <Checkout />
            </LoaderSwitcher>
          )}
        </ConsumerCartLoader>
      )}
    </AppContentLoader>
  )
}
