import { ProductModal } from 'shop/components'
import { CategoryProduct } from 'shop/components/Shop/Categories'

interface LocationProps {
  state: CategoryProduct
}
interface ProductProps {
  location?: LocationProps | any
}

const Product = ({ location }: ProductProps) => {
  let product

  if (location.state) {
    // location.state comes from react-router
    // which we use to pass a full Product record from the previous query
    // to this page for faster loading
    // See ProductCard.tsx for more details

    product = location.state.product
  }

  return (
    <>
      <ProductModal {...product} />
    </>
  )
}

export default Product
