import { OpeningTime, OpeningTimes, Product } from 'shop/types'

export const createOpeningHours = (schedule: OpeningTimes | {}) =>
  Object.keys(schedule)
    .flatMap((key) =>
      schedule[key].map((time: OpeningTime) => {
        if (time?.closed) {
          return null
        }

        const day = capitalize(key)

        return {
          '@type': 'OpeningHoursSpecification',
          dayOfWeek: day,
          opens: time.startTime,
          closes: time.endTime
        }
      })
    )
    .filter((specification) => Boolean(specification))

export const createOffers = (
  product: Product,
  organization: String,
  url: String
) => {
  if (product.variants.length) {
    return product.variants.map((variant) =>
      buildOffer(
        variant.id,
        variant.name,
        variant.inStock,
        variant.price.basePrice,
        url,
        organization
      )
    )
  }

  return [
    buildOffer(
      product.defaultVariantId,
      product.name,
      product.inStock,
      product.minPrice.basePrice,
      url,
      organization
    )
  ]
}

const buildOffer = (
  id: string,
  name: string,
  inStock: boolean,
  price: string,
  url: string,
  organization: string
) => {
  return {
    '@type': 'Offer',
    sku: id,
    name: name,
    availability: inStock
      ? 'http://schema.org/InStock'
      : 'http://schema.org/OutOfStock',
    price: price,
    priceCurrency: 'GBP',
    url: url,
    seller: {
      '@type': 'Organization',
      name: organization
    }
  }
}

export const trimUrl = (url: string) => {
  return url.replace(/\/+$/, '')
}

const capitalize = (string: string) => {
  const firstLetter = string[0].toUpperCase()
  const rest = string.slice(1)

  return `${firstLetter}${rest}`
}
