import React, { HTMLAttributes, useRef } from 'react'
import styled from '@emotion/styled'
import { useShop } from 'shop/hooks'
import Options from './Options'
import { MdErrorOutline as ErrorIcon } from 'react-icons/md'
import Theme from 'shop/theme/types'
import { useMediaQueries } from 'shop/hooks'
import { AiFillPlusCircle, AiOutlineMinusCircle } from 'react-icons/ai'
import { OrderItemV2 } from 'shop/types/cart'
import { getImageURL } from 'shop/utils/common'
import { formatMoney } from './utils'

type StyledHTMLElement = React.DetailedHTMLProps<
  HTMLAttributes<HTMLElement>,
  HTMLElement
>

interface ProductImageProps {
  backgroundImage: string
}

interface Props {
  orderItem: OrderItemV2
  canEditQuantity: boolean
  index: number
  testLabel: string
  isInvalid?: boolean
  handleOrderItemQuantityChange: (
    orderItem: OrderItemV2,
    delta: number,
    proceedWithNewOrderItems?: boolean | undefined
  ) => void
}

const CartItem = ({
  orderItem,
  canEditQuantity,
  testLabel,
  index,
  isInvalid,
  handleOrderItemQuantityChange
}: Props) => {
  const itemContainer = useRef<HTMLDivElement>(null)
  const { config } = useShop()
  const { isTablet, isMobile } = useMediaQueries()

  const { quantity, id, options, product, variantId, modifierGroups, total } =
    orderItem

  const imageUrl = getImageURL(config.domain, config.assetHost, variantId)

  const ItemPrice = () => {
    return (
      <Price data-testid={`${testLabel}ItemPrice[${index}]`}>
        {formatMoney(total.base)}
      </Price>
    )
  }

  /** Shows quantity controls based on canEditQuantity prop */
  const QuantityControls = (): JSX.Element => {
    if (canEditQuantity) {
      return (
        <QuantityPriceContainer>
          <Minus
            data-testid={'decrement-button'}
            onClick={() => handleOrderItemQuantityChange(orderItem, -1)}
          />
          <Quantity>{quantity}</Quantity>
          <Plus
            data-testid={'increment-button'}
            onClick={() => handleOrderItemQuantityChange(orderItem, +1)}
          />
          {isTablet && <ItemPrice />}
        </QuantityPriceContainer>
      )
    } else {
      return (
        <QuantityPriceContainer>
          <Quantity>{quantity}</Quantity>
          {isTablet && <ItemPrice />}
        </QuantityPriceContainer>
      )
    }
  }

  return (
    <Container data-testid='cart-item' key={id} ref={itemContainer}>
      <ProductImage backgroundImage={imageUrl} />
      <ProductDetails>
        <Info>
          <Name>
            {product.name}
            {isInvalid && (
              <ErrorIconContainer>
                <ErrorIcon />
              </ErrorIconContainer>
            )}
          </Name>
          <QuantityControls />
        </Info>
        {(!!modifierGroups.length || !!options?.length) && (
          <Options
            modifierGroups={modifierGroups || []}
            options={options || []}
          />
        )}
        {isMobile && <ItemPrice />}
      </ProductDetails>
    </Container>
  )
}

const ErrorIconContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    color: theme.colors['state']['error'][5],
    margin: 'auto 5px',
    display: 'inline-flex',
    alignContent: 'center'
  })
)

const Info = styled.div<StyledHTMLElement, Required<Theme>>(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  lineHeight: '1.4em',
  alignItems: 'center'
}))

const Container = styled.div<StyledHTMLElement, Required<Theme>>(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center'
}))

const commonIconProperties = {
  width: '20px',
  height: '20px',
  cursor: 'pointer',
  '&:disabled': {
    cursor: 'auto',
    color: '#E5E5E5'
  }
}

const Plus = styled(AiFillPlusCircle)(() => ({
  ...commonIconProperties,
  '&:hover': {
    color: '#2A2A2A'
  },
  '&:active': {
    color: '#414141'
  }
}))

const Minus = styled(AiOutlineMinusCircle)(() => ({
  ...commonIconProperties,
  '&:hover': {
    background: '#F2F2F2',
    borderRadius: '20px'
  },
  '&:active': {
    background: '#E5E5E5',
    borderRadius: '20px'
  }
}))

const QuantityPriceContainer = styled.div<StyledHTMLElement>(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  width: '60%',
  alignItems: 'center'
}))

const Price = styled.div<StyledHTMLElement, Required<Theme>>(({ theme }) => ({
  flexShrink: 0,
  fontSize: theme.fontSizes[2],
  margin: '0',
  fontWeight: 'normal',
  alignItems: 'flex-start',
  justifyContent: 'normal',
  display: 'flex',
  height: '100%',
  [theme['mediaQueries']['viewport7']]: {
    justifyContent: 'flex-end',
    margin: '0 0 0 16px',
    // for warning and loading icons
    alignItems: 'center',
    gap: '3px'
  }
}))

export const Name = styled.h4<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    gap: '3px',
    height: '100%',
    wordBreak: 'break-word',
    fontSize: theme.fontSizes[2],
    fontWeight: 500,
    flexBasis: '60%',
    [theme['mediaQueries']['viewport7']]: {
      alignItems: 'flex-start',
      flexBasis: '40%'
    }
  })
)

const Quantity = styled.strong<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    fontSize: theme.fontSizes[2],
    fontWeight: 400,
    margin: '0 6px'
  })
)

const ProductImage = styled.div<ProductImageProps, Required<Theme>>(
  ({ theme, backgroundImage }) => ({
    [theme['mediaQueries']['viewport6']]: {
      background: `lightgray url(${backgroundImage})`,
      alignSelf: 'flex-start',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      marginRight: '12px',
      borderRadius: '18px',
      flex: '0 0 60px',
      height: '60px'
    },
    [theme['mediaQueries']['viewport7']]: {
      flex: '0 0 45px',
      height: '45px'
    }
  })
)

const ProductDetails = styled.div<StyledHTMLElement, Required<Theme>>(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  flex: 'auto',
  '> strong': {
    margin: 0
  }
}))

export default CartItem
